<template>
  <div>
    <HeroInner descrition="Gestão de Instituição" class="mt-70" />

    <div class="grid-box">
      <v-layout bg-white ma-4 mt-5 column br-4 elevation-base>
        <div class="my-donations-header">
          <div>
            <Icon name="fal fa-hands title-icon" size="32" colorFont="#C3C6D5" />
            <h2>Gestão de instituição</h2>
          </div>
          <div>
            <InputText type="search" :showLoader="false" place-holder="Buscar..." v-model="search" />
          </div>
        </div>

        <div>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="institutions"
            :pagination.sync="pagination"
            :search="search"
            :custom-filter="customFilter"
            :rows-per-page-items="[25, 50, 100, {'text': '$vuetify.dataIterator.rowsPerPageAll', 'value':-1}]"
            select-all
            item-key="name"
            class="text-xs-left"
          >
            <template v-slot:headers="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                  @click="changeSort(header.value)"
                >
                  {{ header.text }}
                  <v-icon small>arrow_upward</v-icon>
                </th>
                <th></th>
              </tr>
            </template>
            <template v-slot:items="props">
              <tr @click="props.selected = !props.selected">
                <td>{{ props.item.entity.name }}</td>
                <td>{{ props.item.entity.socialReason }}</td>
                <td>{{ props.item.entity.creationDate | date }}</td>
                <td>{{ props.item.amountOfUsers }}</td>
                <td>{{ props.item.entity.cnpj | cnpj }}</td>
                <td>{{ props.item.amountOfActiveProjects }}</td>
                <td>{{ getState(props.item) }}</td>
                <td>
                  <button
                    class="btn-line small mr-2"
                    title="Editar"
                    @click="editInstitution(props.item)"
                  >
                    <i class="fal fa-pencil"></i>
                  </button>
                  <button
                    class="btn-line small mr-2"
                    title="Inativar"
                    v-if="props.item.entity.entityStatusId == 1"
                    @click="setEntityStatus(props.item.entity, 2)"
                  >
                    <i class="fal fa-ban"></i>
                  </button>
                  <button
                    class="btn-line small mr-2"
                    title="Ativar"
                    v-if="props.item.entity.entityStatusId == 2"
                    @click="setEntityStatus(props.item.entity, 1)"
                  >
                    <i class="fal fa-power-off"></i>
                  </button>
                </td>
              </tr>
            </template>
            <template v-slot:expand="props">
              <v-card flat>
                <v-card-text>Peek-a-boo!</v-card-text>
              </v-card>
            </template>
          </v-data-table>
        </div>
      </v-layout>
    </div>
  </div>
</template>
<script type="plain/text">
import HeroInner from "@/components/HeroInner.vue";
import InstitutionService from "@/scripts/services/institution.service";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import EntityService from "@/scripts/services/entity.service";
import { Routes } from "@/scripts/models/enums/routes.enum";
import CommonHelper from '../../../scripts/helpers/common.helper';

export default {
  components: {
    HeroInner
  },
  data: () => ({
    pagination: {
      sortBy: "name"
    },
    search: "",
    selected: [],
    headers: [
      { text: "Nome", value: "name", align: "left" },
      {
        text: "Razão Social",
        value: "entity.socialReason",
        align: "center"
      },
      {
        text: "Data de criação",
        value: "entity.creationDate",
        align: "left"
      },
      {
        text: "Quantidade de usuários",
        value: "amountOfUsers",
        align: "left"
      },
      { text: "CNPJ", value: "entity.cnpj", align: "left" },
      {
        text: "Quantidade de projetos",
        value: "amountOfActiveProjects",
        align: "left"
      },
      { text: "UF", value: "entity.address.state", align: "left" }
    ],
    institutions: [],
    institutionService: new InstitutionService(),
    entityService: new EntityService()
  }),
  created() {
    this.loadInstitutionsInformation();
  },
  methods: {
    customFilter(institutions, search) {
      search = search.toLowerCase();

      let found = institutions.filter(
        function(institution) {
          return (
            institution.entity.name.toLowerCase().indexOf(search) !== -1 ||
            institution.entity.socialReason.toLowerCase().indexOf(search) !==
              -1 ||
            institution.entity.cnpj.toLowerCase().indexOf(
              search
                .replace(/\./g, "")
                .replace(/\-/g, "")
                .replace(/\//g, "")
                .replace(" ", "")
            ) !== -1 ||
            this.getState(institution)
              .toLowerCase()
              .indexOf(search) !== -1
          );
        }.bind(this)
      );

      return found;
    },
    getState(institution) {
      if (
        institution &&
        institution.entity &&
        institution.entity.address &&
        institution.entity.address.state
      ) {
        return institution.entity.address.state;
      }

      return "";
    },
    editInstitution(institution) {
      this.$router.push({
        name: Routes.app.InstitutionProfile,
        query: { institutionId: institution.id }
      });
    },
    setEntityStatus(entity, entityStatusId) {
      this.entityService.setEntityStatus(entity.id, entityStatusId).then(
        function(data) {
          if (data.success) {
            entity.entityStatusId = entityStatusId;
          }
        }.bind(this)
      );
    },
    loadInstitutionsInformation() {
      this.institutionService
        .loadInstitutionsInformation()
        .then(this.onInstitutionsLoaded);
    },
    onInstitutionsLoaded(institutions) {
      this.institutions = institutions;
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    }
  },
  filters: {
    cpf: function(cpf) {
      return new FormatterHelper().formatCpf(cpf);
    },
    cnpj: function(cnpj) {
      if (CommonHelper.validateCNPJ(cnpj)) {
        return new FormatterHelper().formatCnpj(cnpj);
      }
      return cnpj;
    },
    date: function(date) {
      return new FormatterHelper().formatDate(date);
    },
    postalCode: function(postalCode) {
      return new FormatterHelper().formatPostalCode(postalCode);
    },
    gender: function(gender) {
      if (gender == "M") {
        return "Masculino";
      } else if (gender == "F") {
        return "Feminino";
      } else if (gender == "O") {
        return "Outros";
      }else if (gender == "-"){
        return "Não informar";
      }

      return gender;
    },
    entityType: function(entityType) {
      switch (entityType) {
        case 0:
          return "AUC";
        case 1:
          return "Instituição";
        case 2:
          return "Empresa";
        case 3:
          return "Concelho";
        default:
          return "-";
      }
    }
  }
};
</script>

<style>
</style> 